<template>
  <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item active">Holidays</li>
      </ol>
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row align-items-center">
        <div class="col-5">
          <h2 class="title2">Holidays</h2>
        </div>
        <div class="col text-end">
          <router-link
            :to="{ name: 'add-holiday' }"
            class="btn btn-outline-primary"
            >Add
          </router-link>
        </div>
      </div>
      <div class="t-layout">
        <div class="row">
          <div class="col-md-12">
            <div v-if="this.$store.state.loader">
              <loader
                object="#f74b3d"
                color1="#ffffff"
                color2="#17fd3d"
                size="5"
                speed="2"
                bg="#343a40"
                objectbg="#999793"
                opacity="80"
                disableScrolling="false"
                name="spinning"
              ></loader>
            </div>
            <div v-else class="card">
              <div class="card-header">
                <flash-message class="myCustomClass"></flash-message>
              </div>
              <div class="card-body">
                <div class="custom-pagination">
                  <div class="row">
                    <div class="col-md-3">
                      <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search data"
                      ></b-form-input>
                    </div>
                    <div class="col-md-9 tx-right">
                      <div class="page-number-input custom-box">
                        <label>Go to Page:</label>
                        <b-form-input
                          class="page-number"
                          v-model="currentPage"
                          type="number"
                          min="1"
                          :max="
                            holidaysData.length > 0
                              ? Math.ceil(holidaysData.length / perPage)
                              : 1
                          "
                        ></b-form-input>
                      </div>
                      <div class="pagination-wrapper custom-box">
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="holidaysData.length"
                          :per-page="perPage"
                          aria-controls="my-table"
                        ></b-pagination>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <b-table
                    class="table table-custom"
                    :items="holidaysData"
                    :fields="fields"
                    :per-page="perPage"
                    :current-page="currentPage"
                    responsive="sm"
                    :sort-compare="mySortCompare"
                    :sort-desc.sync="sortDesc"
                    :sort-by.sync="sortBy"
                    show-empty
                    :filter="filter"
                    sticky-header
                  >
                    <template #head()="{ label, field: { key, sortable } }">
                      {{ label }}
                      <template v-if="sortable">
                        <template>
                          <b-img
                            v-if="sortBy !== key"
                            :src="sortIconNeutral"
                            style="width: 0.65rem; height: 1rem"
                          ></b-img>
                          <span title="Sort By ascending" v-else-if="sortDesc"
                            ><b-img
                              :src="sortIconAsc"
                              style="width: 0.65rem; height: 1rem"
                            ></b-img
                          ></span>
                          <span v-else title="Sort By descending"
                            ><b-img
                              :src="sortIconDesc"
                              style="width: 0.65rem; height: 1rem"
                            ></b-img
                          ></span>
                        </template>
                      </template>
                    </template>
                    <template v-slot:cell(description)="data">
                      <p :title="data.item.reason">{{ data.item.reason }}</p>
                    </template>
                    <template v-slot:cell(from)="data">
                      {{ data.item.leavefrom }}
                    </template>
                    <template v-slot:cell(to)="data">
                      {{ data.item.leaveto }}
                    </template>
                    <template v-slot:cell(return_date)="data">
                      {{ data.item.returndate }}
                    </template>

                    <template v-slot:cell(action)="data">
                      <div class="d-inline-block">
                        <div class="d-flex justify-content-md-around">
                          <a
                            class="button button1"
                            title="Edit"
                            @click.prevent="Edit(data.item.id)"
                            ><i class="icon-pencil"></i
                          ></a>
                          <a
                            class="button button1"
                            title="Delete"
                            @click.prevent="deleteHoliday(data.item.id)"
                            ><i class="icon-bin"></i
                          ></a>
                        </div>
                      </div>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import axios from "axios";

export default {
  data() {
    return {
      currentPage: 1,
      perPage: 15,
      sortIconNeutral:
        "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e",
      sortIconDesc:
        "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e",
      sortIconAsc:
        "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e",
      sortBy: null,
      sortDesc: false,
      filter: null,
      fields: [
        {
          key: "date",
          label: "Date",
          sortable: true,
          tdAttr: { "custom-label": "Date:" },
        },
        {
          key: "day",
          label: "Day",
          sortable: true,
          tdAttr: { "custom-label": "Day:" },
        },
        {
          key: "name",
          label: "Name",
          sortable: true,
          tdAttr: { "custom-label": "Name:" },
        },
        {
          key: "action",
        },
      ],
    };
  },
  computed: {
    holidaysData() {
      return this.$store.state.holidaysData;
    },
  },
  mounted() {
    this.$store.dispatch("switchLoader", true);
    this.$store.dispatch("loadHolidaysdata");
    if (localStorage.getItem("page_number")) {
      this.currentPage = localStorage.getItem("page_number");
      localStorage.removeItem("page_number");
    }
  },
  methods: {
    Edit(id) {
      localStorage.setItem("page_number", this.currentPage);
      this.$router.push({ name: "holiday-update", params: { id: id } });
    },
    mySortCompare(a, b, key) {
      var firstDate;
      var secondDate;
      if (key == "from") {
        firstDate = new Date(a.leavefrom);
        secondDate = new Date(b.leavefrom);
      } else if (key == "to") {
        firstDate = new Date(a.leaveto);
        secondDate = new Date(b.leaveto);
      } else if (key == "return_date") {
        firstDate = new Date(a.returndate);
        secondDate = new Date(b.returndate);
      }
      if (key == "from" || key == "to" || key == "return_date") {
        firstDate = firstDate.getTime();
        secondDate = secondDate.getTime();
        return firstDate - secondDate;
      } else {
        return false;
      }
    },
    /* formatTime(time){
        return moment(time).format('hh:mm A');
      }, */
    deleteHoliday(id) {
      if (confirm("Are you sure, you want to delete this Holiday ?")) {
        axios.get("/holidays/delete/" + id).then(({ data }) => {
          if (data.success) {
            this.flashMessage.success({
              message: data.success,
              time: 3000,
            });
            this.$store.dispatch("loadHolidaysdata");
          } else {
            this.flashMessage.error({
              message: "Something went wrong!",
              time: 3000,
            });
          }
        });
      }
    },
  },
};
</script>
